import * as React from 'react';
import { Link, withPrefix } from 'gatsby';
import {
  Navbar,
  Nav,
  NavItem,
  NavbarToggler,
  Collapse,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import { Location, LocationContext } from '@reach/router';
import * as qs from 'query-string';
const classnames = require('classnames');

import FrogoLogo from './FrogoLogo';

import './TopNavBar.scss';

const isLandingPage = (context: LocationContext) => {
  return 'landingpage' in qs.parse(context.location.search);
};

const PRODUCT_DROP_DOWN_LINKS = {
  analysis: '/analyse',
  textAdsGenerator: '/ad-mechanic'
};

const navDropdownToggleClasses = (
  baseClassName: string,
  activeClassName: string,
  subItems: string[],
  context: LocationContext
) => {
  const isSubItemActive = subItems.some((item: string) =>
    context.location.pathname.startsWith(withPrefix(item))
  );
  return classnames(baseClassName, { [activeClassName]: isSubItemActive });
};

interface TopNavBarState {
  isOpen: boolean;
}
interface TopNavBarProps {
  transparent?: boolean;
  className?: string;
}

class TopNavBar extends React.Component<TopNavBarProps, TopNavBarState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      isOpen: false
    };
  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  render() {
    const classes = classnames(this.props.className, {
      'TopNavBar-transparent': this.props.transparent,
      'TopNavBar-dark': !this.props.transparent
    });
    const navItemClasses = classnames(
      'nav-link',
      'ml-0',
      'ml-md-2',
      'TopNavBar-nav-item',
      { 'TopNavBar-nav-item-transparent': this.props.transparent },
      { 'TopNavBar-nav-item-dark': !this.props.transparent }
    );
    const navItemActiveClasses = classnames(
      { 'TopNavBar-nav-item-active-transparent': this.props.transparent },
      { 'TopNavBar-nav-item-active-dark': !this.props.transparent }
    );
    const navbarBrandClasses = 'navbar-brand TopNavBar-ml-ie';
    const frogoLogo = this.props.transparent ? (
      <FrogoLogo height="36" uniColor={true} color={'white'} />
    ) : (
      <FrogoLogo height="36" />
    );
    return (
      <Location>
        {(context: LocationContext) => (
          <Navbar fixed="top" className={classes} expand="md">
            {isLandingPage(context) ? (
              <a href="#top" className={navbarBrandClasses}>
                {frogoLogo}
              </a>
            ) : (
              <Link to="/" className={navbarBrandClasses}>
                {frogoLogo}
              </Link>
            )}
            {!isLandingPage(context) && (
              <>
                <NavbarToggler
                  onClick={this.toggle}
                  className="TopNavBar-toggler"
                />
                <Collapse isOpen={this.state.isOpen} navbar={true}>
                  <Nav navbar={true} className="ml-auto">
                    <UncontrolledDropdown nav={true} inNavbar={true}>
                      <DropdownToggle
                        nav={true}
                        className={navDropdownToggleClasses(
                          navItemClasses,
                          navItemActiveClasses,
                          Object.values(PRODUCT_DROP_DOWN_LINKS),
                          context
                        )}
                        // Avoid href="#" which causes scroll to top on click
                        tag="span"
                        style={{ cursor: 'pointer', userSelect: 'none' }}
                      >
                        Produkte
                      </DropdownToggle>
                      <DropdownMenu right={true} className="fg-card-shadow">
                        <DropdownItem
                          className="TopNavBar-dropdown-item"
                          // Required to work in Firefox
                          tag="div"
                        >
                          <Link
                            to={PRODUCT_DROP_DOWN_LINKS.analysis}
                            className="TopNavBar-dropdown-item-link"
                            activeClassName="active"
                          >
                            Analyse
                          </Link>
                        </DropdownItem>
                        <DropdownItem
                          className="TopNavBar-dropdown-item"
                          // Required to work in Firefox
                          tag="div"
                        >
                          <Link
                            to={PRODUCT_DROP_DOWN_LINKS.textAdsGenerator}
                            className="TopNavBar-dropdown-item-link"
                            activeClassName="active"
                          >
                            Ad Mechanic
                          </Link>
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    <NavItem>
                      <Link
                        to="/ueber-uns"
                        className={navItemClasses}
                        activeClassName={navItemActiveClasses}
                      >
                        Über Uns
                      </Link>
                    </NavItem>
                  </Nav>
                </Collapse>
              </>
            )}
          </Navbar>
        )}
      </Location>
    );
  }
}

export default TopNavBar;
